import React, { MutableRefObject, useEffect, useRef, useState } from "react";

type UseElementInViewProps = IntersectionObserverInit & {
    triggerOnce?: boolean;
    delay?: number;
}

type UseElementInViewResult<T extends HTMLElement> = {
    ref: MutableRefObject<T | null>;
    isInView: boolean;
    entry: IntersectionObserverEntry | undefined;
}

export default function useElementInView<T extends HTMLElement>(options: UseElementInViewProps): UseElementInViewResult<T> {
    const [isInView, setIsInView] = useState(false);
    const [isTriggered, setIsTriggered] = useState(false);
    const [entry, setEntry] = useState<IntersectionObserverEntry | undefined>(undefined);
    const targetRef = useRef<T>(null);
    const timer = useRef<number>(-1);

    useEffect(() => {
        return () => {
            if (timer.current !== -1) {
                window.clearTimeout(timer.current);
            }
        }
    }, []);

    useEffect(() => {
        const r = targetRef.current;
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;

            if ((options.delay ?? 0) > 0 && entry.isIntersecting) {
                timer.current = window.setTimeout(() => {
                    setIsInView(entry.isIntersecting);
                    setEntry(entry);
                    timer.current = -1;
                }, options.delay!)
            } else {
                setIsInView(entry.isIntersecting);
                setEntry(entry);
            }

            if (options.triggerOnce && entry.isIntersecting) {
                setIsTriggered(true);
            }
        }, options);

        if (r && !isTriggered) {
            observer.observe(r);
        }

        return () => {
            if (r) {
                observer.unobserve(r);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    return { isInView, ref: targetRef, entry };
};
