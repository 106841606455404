"use client"
import cn from "classnames";
import { useRouter } from "next/router";
import { useEffect, useReducer } from 'react';
import Ad from 'src/components/ads/Ad';
import FilmwebLink from "src/components/filmweblink/FilmwebLink";
import { ISearchAction } from 'src/components/search/ISearchAction';
import ResultPosterRow from 'src/components/search/ResultPosterRow';
import Search from 'src/components/search/Search';
import Toolbox from 'src/components/streaming/streamingtoolbox/Toolbox';
import FilmwebSGLogoSvg from "src/components/svg/FilmwebSGLogoSvg";
import theme from 'src/components/themes/DefaultTheme';
import useElementInView from "src/lib/client/useElementInView";
import { useAzSearch } from 'src/lib/client/useSearch';
import styled from 'src/lib/styles/css';
import IStreamingToolbox from 'src/lib/types/StreamingToolbox';


//#region [Props]
type StreamingSearchBoxProps = {
    className?: string;
    maxResults?: number;
    showLogo?: boolean;
    toolbox?: IStreamingToolbox;
    fullWidth?: boolean;
};
//#endregion

//#region [Component]
export default function StreamingSearchBox({ className, toolbox, maxResults = 6, showLogo = true, fullWidth = false }: StreamingSearchBoxProps) {
    const [state, dispatch] = useReducer(sgSearchReducer, EMPTY_STATE);
    const { searchLoading: azSearchLoading, searchLoaded: azSearchLoaded, searchResult: azSearchResult } = useAzSearch(state.searchExpression, { streamingOnly: true, active: !!state.searchExpression.trim(), customSortNum: process.env.NEXT_PUBLIC_SEARCH_CUSTOM_SORT });

    const router = useRouter();
    const { ref, isInView } = useElementInView({ threshold: 1.0, triggerOnce: true });

    useEffect(() => {
        // when next.js reports the route is about to change, close the menu
        const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
            dispatch({ type: "CLEAR_SEARCH" });
        };

        router.events.on('routeChangeStart', handleRouteChange);
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        }
    }, [router]);

    useEffect(() => {
        if (isInView) {
            window?.dataLayer?.push({ 'event': 'fw_SGToolbox_view' });

        }
    }, [isInView]);

    const searchLoading = azSearchLoading;
    const searchLoaded = azSearchLoaded;
    const searchResult = azSearchResult?.results;


    return <SStreamingSearchBox className={cn(className, fullWidth ? "fullWidth" : undefined)} ref={ref}>
        {showLogo && <SLogoLink to="/streamingguide" aria-label="Gå til Streamingguide forsiden" ><SFilmwebSGLogoSvg /></SLogoLink>}
        <SSearch
            dispatch={dispatch}
            searchExpression={state.searchExpression}
            showSearchResults={state.showSearchResults}
            placeholderText="Søk etter film eller serie"
            isSearching={searchLoading}
            useIconGradient />
        {!searchLoading && searchLoaded && (searchResult?.length ?? 0) === 0 && <SNoHits>Ingen treff</SNoHits>}
        {(searchResult?.length ?? 0) > 0 && <>
            <SResultPosterRow
                searchResult={searchResult!}
                maxResults={maxResults}
                disabled={searchLoading}
                forceStreaming
                clickTracker={(id, title) => { trackHitClick(id, title, state.searchExpression) }}
            />
        </>}

        <Toolbox toolbox={toolbox} />
    </SStreamingSearchBox>;
}
//#endregion

//#region [Other]
interface ISGSearchState {
    showSearchResults: boolean;
    searchExpression: string;
    hasFiredFirstFocus: boolean;
}

const EMPTY_STATE: ISGSearchState = {
    showSearchResults: false,
    searchExpression: "",
    hasFiredFirstFocus: false
};

function sgSearchReducer(state: ISGSearchState, action: ISearchAction): ISGSearchState {
    switch (action.type) {
        case 'BEGIN_SEARCH':
            if (!state.hasFiredFirstFocus) {
                trackFirstFocusPrPageview();
            }
            return { ...state, showSearchResults: true, hasFiredFirstFocus: true };
        case 'END_SEARCH':
            return { ...state, showSearchResults: false };
        case 'CLEAR_SEARCH':
            return { ...state, showSearchResults: false, searchExpression: "" };
        case 'DO_SEARCH':
            return { ...state, searchExpression: action.searchExpression ?? "", showSearchResults: true };
        default:
            return state;
    }
}

function trackFirstFocusPrPageview() {
    window?.dataLayer?.push({
        'event': 'fw_Quicksearch_sg',
        'eventCategory': 'SG Søk Start',
        'eventAction': "1. klikk"
    });
}


function trackHitClick(id: number | string, title: string, searchExpression: string) {
    window?.dataLayer?.push({
        'event': 'fw_Quicksearch_sg',
        'eventCategory': 'SG Søk Treff',
        'eventAction': `${title} | ${id}`,
        'eventLabel': searchExpression
    });
}


//#endregion

//#region [Styles]
const SStreamingSearchBox = styled.section`
	// TODO: These colors are not int the design manual!
	--custom-gradient-stop0: ${theme.palette.sgGradientStops[0].color};
	--custom-gradient-stop1: ${theme.palette.sgGradientStops[1].color};
	//background-color: ${theme.sgColor};
	background: linear-gradient(270deg, var(--custom-gradient-stop0) 0%, var(--custom-gradient-stop1) 100%);
	color: var(--textcolor);
	text-align: center;
	padding: 25px 10px 25px 10px;
	margin-left: -10px;
	margin-right: -10px;
	position: relative;

	@media ${theme.mq.desktop} {
		border-radius: 8px;
		margin: 0;
		padding: 25px;
	}

    &.fullWidth {
        @media ${theme.mq.desktop} {
            //@media  (orientation: landscape) {
            max-width: calc(100% + 30px);
            margin-left: -15px;
            margin-right: -15px;
        }
    }
`;

const SSearch = styled(Search)`
	max-width: 800px;
	padding-left: 0;
	margin: 0 auto;

	.searchInput {
		height: 60px;
		border-radius: 30px 0 0 30px;
		min-width: 60px;
		font-size: 20px;

		outline: var(--textcolor);

		&:focus::placeholder {
			opacity: 0.5;
		}
	}

	.searchButton {
		height: 60px;
		border-radius: 0 30px 30px 0;
		width: 50px;
		color: ${theme.textColor};
		padding: 5px 15px 5px 4px;
		text-align: right;
		flex: 0 0 auto;
		cursor: pointer;
	}

`;

const SNoHits = styled.p`
	text-align: center;
`;


const SAdPixel = styled(Ad)`
	width: 1px;
	height: 1px;
`;

const SResultPosterRow = styled(ResultPosterRow)`
	--textcolor: ${theme.bgColor};
	--inlinelinkcolor: ${theme.bgColor};
`;

const SLogoLink = styled(FilmwebLink)`
	display: block;
	max-width: min(85%, 800px);

	margin: 0 auto;

	@media ${theme.mq.desktop} {
		max-width: min(75%, 600px);
	}
`;

const SFilmwebSGLogoSvg = styled(FilmwebSGLogoSvg)`
	display: block;

	width: 100%;

	margin: 0 auto 1em auto;
	line-height: 1;

	path {
		fill: var(--bgcolor) !important;
	}
`;


//#endregion